// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // загружает переводы с сервера
  .use(LanguageDetector) // автоматически определяет язык пользователя
  .use(initReactI18next) // подключает react-i18next
  .init({
    fallbackLng: 'en', // язык по умолчанию
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // путь к файлам с переводами
    },
    react: {
      useSuspense: false, // отключает Suspense (если не используете его)
    }
  });

export default i18n;
