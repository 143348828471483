import React, { useState } from "react";
import backendHost from "./backendTools/backendHost";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const minPasswordLength = 5;

const allowedUsernameChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

const RegistrationEntry = ({onRegister}) => {

    const [isMailValid, setMailValid] = useState(true);
    const [isPasswordDoubled, setPasswordDoubled] = useState(true);
    const [isPasswordShort, setPasswordShort] = useState(false);
    const [isUsernameInvalid, setUsernameInvalid] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    async function handleRegistration(event) {
        event.preventDefault();
        const mail = document.getElementById("email").value;
        const pass = document.getElementById("password1").value;
        const user = document.getElementById("username").value;
        //TODO process data before sending to backend
        const userMail = mail.trim();
        const userNick = user.trim();
        const registrationForm = {
            email    : userMail,
            username : userNick,
            password : pass
        };
        await axios.post(backendHost + "/user/registration", registrationForm)
            .catch(function (error) {
                if (error.response) {
                    setAlertMessage(error.response.data);
                }
            })
            .then((registration)=> {
                if(registration.status === 200) {
                    onRegister(userNick, userMail);
                    navigate("/checkMail");
                }
            });
        
    }

    function isEmailValid(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateEmail(event) {
        const newMailValue = event.currentTarget.value.trim();
        if(newMailValue !== '') {
            var isMailValid = isEmailValid(newMailValue);
            setMailValid(isMailValid);
            if(isMailValid) {
                
            }
        } else {
            setMailValid(true);
        }
    }

    function validatePasses() {
        const pass1 = document.getElementById("password1").value;
        const pass2 = document.getElementById("password2").value;
        if(pass2) {
            if(pass1 === pass2) {
                setPasswordDoubled(true);
            } else {
                setPasswordDoubled(false);
            }
        }
        if(pass1.length < minPasswordLength) {
            setPasswordShort(true);
        } else {
            setPasswordShort(false);
        }
    }

    function checkUsernameSymbols(event) {
        const regex = new RegExp(`^[${allowedUsernameChars}]+$`);
        if(event.currentTarget.value !== '') {
            if(regex.test(event.currentTarget.value)) {
                setUsernameInvalid(false);
            } else {
                setUsernameInvalid(true);
            }
        } else {
            setUsernameInvalid(false);
        }
        
    }

    function checkUsernameUniqness() {

    }

    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        {alertMessage}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} aria-label={t('action.close')}></button>
                                    </div>}
                    <form className="p-2" onSubmit={handleRegistration}> 
                        <div className="p-2">
                            <input  type="text" 
                                    id="email"
                                    className="form-control" 
                                    placeholder="E-mail"
                                    onBlur={validateEmail}
                                    required/>
                            {!isMailValid && <p style={{ color: 'red' }}>{t('invalid.email')}</p>}
                        </div>
                        <div className="p-2">
                            <input  type="password" 
                                    id="password1"
                                    className="form-control"
                                    placeholder={t('label.password')} 
                                    onBlur={validatePasses}
                                    required/>
                            {isPasswordShort && <p style={{ color: 'red' }}>{t('password.is.too.short')}</p>}
                        </div>
                        <div className="p-2">
                            <input  type="password" 
                                    id="password2"
                                    className="form-control"
                                    placeholder={t('repeat.password')} 
                                    onBlur={validatePasses}
                                    required/>
                            {!isPasswordDoubled && <p style={{ color: 'red' }}>{t('enter.the.same.password')}</p>}
                        </div>
                        <div className="p-2">
                            <input  type="text" 
                                    id="username"
                                    className="form-control"
                                    placeholder={t('label.username')} 
                                    onChange={checkUsernameSymbols}
                                    onBlur={checkUsernameUniqness}/>
                        </div>
                        { isUsernameInvalid && <p style={{ color: 'red' }}>{t('use.just.letters')}</p>}
                        <div className="p-2">
                            <button type="submit"  className="form-control btn btn-outline-success"> {t('action.confirm')} </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegistrationEntry;
