import React, {useState, useEffect} from 'react';
import MyItemsAxios from './backendTools/MyItemsAxios';
import { useParams, useLocation } from 'react-router-dom';

import { getLocations } from './LocationsList';
import PageScroll from './components/PageScroll';
import RenderListItems from './RenderListItems';
import ItemAddButton from './components/ItemAddButton';

const requestSearchUtl = "/item/search"
const requestCategoriesURl = "/category";

const pageNumberPropertyName = "page-number";
const pageSizePropertyName = "page-size";
const categoryFilterPropertyName = "category";
const locationFilterPropertyName = "location-id";
const conditionFilterPropertyName = "condition";

const defaultPageSize = 5;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ListItems({userPageSize}) {

  const controller = new AbortController();
  const [itemsPagingData, setItemsPagingData] = useState(null);
  const timeoutId = setTimeout(() => controller.abort(), 30000);  // 30 seconds
  const [categories, setCategoriesList] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  
  var { pageNumber } = useParams();
  const query = useQuery();
  const categoryIdSet = query.get('category');
  const locationIdSet = query.get('location-id');
  const conditionSet = query.get('condition');
  const createTimeStart   = query.get('create-start') || '';
  const createTimeEnd     = query.get('create-end') || '';
  const updateTimeStart   = query.get('update-start') || '';
  const updateTimeEnd     = query.get('update-end') || '';

  if(!pageNumber) {
    pageNumber = 1;
  }

  useEffect(() => {

    function getItemsPagingUrl(pageNumber, pageSize) {
        const pageNumberRequest = pageNumberPropertyName + '=' + pageNumber;
        const pageSizeRequest = pageSizePropertyName + '=' + (pageSize ? pageSize : defaultPageSize);
        const categoryRequest = categoryFilterPropertyName + '=' + (categoryIdSet!=null ? categoryIdSet : '');
        const locationRequest = locationFilterPropertyName + '=' + (locationIdSet!=null ? locationIdSet : '');
        const conditionRequest = conditionFilterPropertyName + '=' + (conditionSet!=null ? conditionSet : '');
        const createdDateRequest = `start-created-date=${createTimeStart}&end-created-date=${createTimeEnd}`;
        const updatedDateRequest = `start-updated-date=${updateTimeStart}&end-updated-date=${updateTimeEnd}`;
        return (requestSearchUtl + '?' + 
          pageNumberRequest + 
          '&' + pageSizeRequest + 
          '&' + categoryRequest +
          '&' + locationRequest + 
          '&' + conditionRequest +
          '&' + createdDateRequest + 
          '&' + updatedDateRequest);      
    }

    // Function to fetch data
    const fetchData = async () => {
      
      clearTimeout(timeoutId);
      var pageRequestURl = getItemsPagingUrl(pageNumber-1, userPageSize);
      try {
        const response = await MyItemsAxios.get(pageRequestURl);
        setItemsPagingData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetch function
    async function getCategoriesList() {
      try {
        const respondedCategories = await MyItemsAxios.get(requestCategoriesURl);
        setCategoriesList(respondedCategories.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }


    getCategoriesList()
    .then(fetchData);
    getLocations()
    .then(setLocationsList);
  }, [categoryIdSet]); 

  var pageList = [];
  if(itemsPagingData) {
    for(var j = 1; j <= itemsPagingData.totalPages; j++) {
      pageList[j] = j;
    }
  }

  return (
  <div className="container align-content-center mt-5 p-3" style={{'maxWidth': '700px'}}>
    <div className='row'>
      <PageScroll pageList={pageList} currentPage={pageNumber} query={query} />
      <RenderListItems itemsPagingData={itemsPagingData} categoriesList={categories} locationsList={locationsList} />
      <ItemAddButton />
      <PageScroll pageList={pageList} currentPage={pageNumber} query={query} />
    </div>
  </div>);
}

export default ListItems;
