import React from 'react';

const defaultCategoryBageClassName = "d-inline-flex m-2 px-2 py-1 fw-semibold text-success-emphasis bg-success-subtle border border-success-subtle rounded-2";

const ItemCategoryBages = ({ categoryList, onClick }) => {

  function handleClick(categoryId) {
    if(onClick != null) {
      onClick(categoryId);
    }
  }

  return (
    <div className='col-12 p-0'>
      {categoryList.map((category, index) => (   
          <small  key={index} onClick={()=>{handleClick(category.id);}} 
                  className={defaultCategoryBageClassName}
                  style={ (onClick) && { cursor: 'pointer' } }>
            {category.name} </small>
      ))}
    </div>
  );
};

export default ItemCategoryBages;
