import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MyItemsAxios from './backendTools/MyItemsAxios';
import transformTree, { getCategoryIdByKey } from './categoryTools/CategoryTree';
import ModalConfirm from './components/ModalConfirm';
import CategoriesCreator from './CategoriesCreator';

const requestCategoriesURl = "/category";
const nullCategory = {id : null, name : '', parent : 0 };

function CategoriesList () {
    const [categoryTree, setTree] = useState(null); 
    const [categoriesArray, setCategoriesArray] = useState([]);

    const [isEditorOpen, setEditorOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(nullCategory);

    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState('');
    const [deletingMessage, setDeletingMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    function openEditor(categoryId) {
        console.log("savedCategories:", categoriesArray);
        if(categoryId) {
            setEditingCategory(categoriesArray.find( listedCategory => listedCategory.id == categoryId));
        } else {
            setEditingCategory(nullCategory);
        }
        setEditorOpen(true);
    }

    function closeEditor(category) {
        if(category) {
            const foundIndex = categoriesArray.findIndex(cat => cat.id == category.id);
            if(foundIndex !== -1) {
                categoriesArray[foundIndex] = category;
            } else {
                categoriesArray.push(category);
            }
            
            setCategoriesArray(categoriesArray);
            setTree(transformTree(categoriesArray));
        }
        setEditorOpen(false);
    }

    function deleteCategory() {
        MyItemsAxios.delete(requestCategoriesURl + '/' + deletingCategory.id)
            .then(()=>{navigate(0)});
    }

    const renderTreeNodes = (data) =>
        data.map((item) => {
          if (item.children) {
            return {
              title: (
                <span>
                    {item.title}
                    {item.children.length === 0 && <span>  <i className="text-danger bi bi-trash p-1" onClick={(e)=>{e.stopPropagation(); setDeleteModalState(true); setDeletingCategory(item);}}></i>  </span>}
              </span>
              ),
              key: item.key,
              children: renderTreeNodes(item.children),
            };
          }
    
          return {
            title: (
              <span>
                {item.title}
                <span>  <i className="text-danger bi bi-trash p-1" onClick={(e)=>{e.stopPropagation(); setDeleteModalState(true); setDeletingCategory(item);}}></i>  </span>
              </span>
            ),
            key: item.key,
          };
        });

    function onNodeClick(selectedKeys, info) {
        const selectedCategoryId = getCategoryIdByKey(selectedKeys[0]);
        openEditor(selectedCategoryId);        
    }

    useEffect(() => {

        function processCategories(categories) {
            setCategoriesArray(categories);
            setTree(transformTree(categories));
        }
        
        async function getCategories () {
            try {
                const respondedCategories = await MyItemsAxios.get(requestCategoriesURl);
                //console.log("Succes received categories", respondedCategories.data);
                return respondedCategories.data;
            } catch (error) {
                console.error('Error receiving data:', error);
            }
        };

        getCategories()
        .then(processCategories);
        

    }, []);

    useEffect(() => {
        var newMessage = t('category.delete');
        newMessage += ' "' + deletingCategory.title + '"?';
        setDeletingMessage(newMessage);
    }, [deletingCategory]);

    return (
        <>
            {categoryTree && (
                <div className="row border rounded-4 bg-light">
                    <div className='col-12 p-3'>
                        <Tree   treeData={renderTreeNodes(categoryTree)}
                                showLine
                                onSelect={onNodeClick}
                                defaultExpandAll 
                                className='bg-light'/>
                    </div>
                    <div className='col-12 m-3'>
                        <span className='btn btn-outline-success' onClick={()=>{openEditor(0);}}> <i className="bi bi-file-earmark-plus"></i></span>
                    </div>
                </div>
            )}
            <CategoriesCreator  isOpen={isEditorOpen}
                                handleClose={closeEditor}
                                category={editingCategory}
                                categoriesList={categoriesArray}/>
            <ModalConfirm message={deletingMessage}
                          isOpen={deleteModalState}
                          onConfirm={deleteCategory}
                          onDecline={()=>{setDeleteModalState(false);}}/>          
        </>
    );
}

export default CategoriesList;