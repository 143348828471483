import React, { useEffect, useState } from "react";
import {Container, Row, Col, Card } from 'react-bootstrap';
import { Statistic } from 'antd';
import MyItemsAxios from "./backendTools/MyItemsAxios";
import { useTranslation } from 'react-i18next';
import Statistics from "./components/Statistics"
import RenderListItems from "./RenderListItems";
import { getLocations } from "./LocationsList";
import ItemInserter from "./ItemInserter";

const requestSearchUtl = "/item/search?page-number=0&page-size=3&category=&location-id=&condition=&start-created-date=&end-created-date=&start-updated-date=&end-updated-date=";
const requestCategoriesURl = "/category";

const MainPage = ({openCreateItem}) => {

    const [itemsPagingData, setItemsPagingData] = useState(null);
    const [categoriesList, setCategoriesList] = useState(null);
    const [locationsList, setLocationsList] = useState(null);
    const [isCreateItemOpened, setCreateItemOpened] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
    
        // Function to fetch data
        const fetchData = async () => {
          try {
            const response = await MyItemsAxios.get(requestSearchUtl);
            setItemsPagingData(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        // Call the fetch function
        async function getCategoriesList() {
          try {
            const respondedCategories = await MyItemsAxios.get(requestCategoriesURl);
            setCategoriesList(respondedCategories.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
    
        getCategoriesList()
        .then(fetchData);
        getLocations()
        .then(setLocationsList);
      }, []); 

    return (
        <Container className="pt-5 mt-5" style={{"maxWidth":'700px'}}>
            <Row>
                <Col xs={12}>
                    <Row className="">
                        <Col xs={12} md={6} className="mb-4">
                            <Card className="rounded-4 text-center">
                                <a href="" className="link-body-emphasis link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover" onClick={(e)=>{e.preventDefault(); setCreateItemOpened(true);}}>
                                <Card.Body className="rounded-4 bg-success">
                                    <Statistic
                                    title={<span className="text-white">{t('item.add.new')}</span>}
                                    value="+1"
                                    valueStyle={{ fontSize: '2em', color: 'white' }}
                                    />
                                </Card.Body>
                                </a>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} className="mb-4">
                            <Card className="rounded-4 text-center">
                                <a href="/help" className="link-body-emphasis link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover">
                                <Card.Body className={'rounded-4 bg-primary'}>
                                    <Statistic
                                    title={<span className="text-white">{t('label.how.to')}</span>}
                                    valueRender={()=>{return(<i className="bi bi-info-circle text-white"></i>);}}
                                    valueStyle={{ fontSize: '2em' }}
                                    />
                                </Card.Body>
                                </a>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Statistics />
                </Col>
                <Col xs={12}>
                    <RenderListItems 
                        itemsPagingData={itemsPagingData} 
                        categoriesList={categoriesList} 
                        locationsList={locationsList} />
                </Col>
            </Row>
            <ItemInserter isOpen={isCreateItemOpened} handleClose={()=> {setCreateItemOpened(false);}}/>
        </Container>
    );
}

export default MainPage;