import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import { useNavigate, useLocation } from "react-router-dom";
import { Tree } from "antd";
import 'antd/dist/reset.css';

import { addDays } from 'date-fns';

import { useTranslation } from 'react-i18next';
import transformTree, {getCategoryIdByKey, getCategoryKeyById, isCategoryEndByKey} from '../categoryTools/CategoryTree';
import { requestLocationsListURl } from "../LocationsList";
import CalendarInput from "./CalendarInput";
import DateToString from "./DateToString";

//const antdLocales = { en:enGB, he:heIl, ru:ruRU, ua:ukUA};
//const momentLocales = {en:'en-gb', he:'he', ru:'ru', ua:'uk'};
const requestCategoriesURl = "/category";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ModalSelector = ({isOpen, onClose}) => {
    const query = useQuery();
    const categoryIdSet     = query.get('category');
    const locationIdSet     = query.get('location-id')  || '';
    const conditionSet      = query.get('condition')    || '';
    const createTimeStart   = query.get('create-start');
    const createTimeEnd     = query.get('create-end');
    const updateTimeStart   = query.get('update-start');
    const updateTimeEnd     = query.get('update-end');

    const [categoryTree, setTree] = useState(null);
    const [locations, setLocations] = useState([]);

    const [checkedCategories, setCheckedKeys] = useState([]);
    const [checkedServisability, setServisabilities] = useState(conditionSet);
    const [checkedLocation, setLocation] = useState(locationIdSet);
    
    const [datesCreation, setDatesCreation] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
    });
    const [datesUpdate, setDatesUpdate] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: 'selection',
    });
    const [creationSet, setCreationSet] = useState((createTimeStart!= null && createTimeEnd != null));
    const [updateSet, setUpdateSet] = useState((updateTimeStart!= null && updateTimeEnd != null));
    
    const { i18n, t } = useTranslation();
    const userLocale = i18n.language;

    const navigate = useNavigate();

    function handleClose() {
        if(onClose != null) {
            onClose();
        }
    }

    const onCategoryCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };

    const onConditionCheck = (event) => {
        setServisabilities(event.target.value);
    };

    const onLocationCheck = (event) => {
        setLocation(event.target.value);
    };

    function onCreationDateChange(dates, setState) {
        setCreationSet(setState);
        if(setState) {
            setDatesCreation(dates);
        }
    }

    function onUpdateDateChange(dates, setState) {
        setUpdateSet(setState);
        if(setState) {
            setDatesUpdate(dates);
        }
    }

    function handleConfirm() {
        var categoriesRequest = '';
        var selectedCategories = [];
        checkedCategories.forEach(categoryKey => {
            if(isCategoryEndByKey(categoryKey)) {
                selectedCategories.push(getCategoryIdByKey(categoryKey));
            }
            categoriesRequest = `&category=${selectedCategories}`;
        });

        var createdIn = '';
        if(creationSet) {
            const startDate = DateToString(datesCreation.startDate);
            const endDate   = DateToString(datesCreation.endDate);
            const formattedString = `&create-start=${startDate}&create-end=${endDate}`;
            createdIn = formattedString;
        }

        var editedIn = '';
        if(updateSet) {
            const startDate = DateToString(datesUpdate.startDate);
            const endDate   = DateToString(datesUpdate.endDate);
            const formattedString = `&update-start=${startDate}&update-end=${endDate}`;
            editedIn = formattedString;
        } 
        
        var servisabilityRequest = '';
        if(checkedServisability)
            servisabilityRequest = `&condition=${checkedServisability}`;

        var locationRequest = '';
        if(checkedLocation != '') {
            locationRequest = `&location-id=${checkedLocation}`;
        }

        if(onClose != null) {
            onClose();
        }
        navigate(   "/page/1?" + categoriesRequest + 
                    locationRequest + 
                    servisabilityRequest + 
                    createdIn + 
                    editedIn);
        window.location.reload();
    }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const respondedCategories = await MyItemsAxios.get(requestCategoriesURl);
                //console.log("Succes received categories", respondedCategories.data);
                return respondedCategories.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchLocations = async () => {
            try {
                const respondedLocations = await MyItemsAxios.get(requestLocationsListURl);
                //console.log("Succes received categories", respondedCategories.data);
                return respondedLocations.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        function checkCategories() {
            if(categoryIdSet != null) {
                const categoryIds = categoryIdSet.split(',');
                var categoriesPreset = [];
                categoryIds.forEach(categoryId => {
                    if(categoryId != "")
                        categoriesPreset.push(getCategoryKeyById(categoryId));
                });
                setCheckedKeys(categoriesPreset);
            }
        }

        function checkDates() {
            if(createTimeStart != null && createTimeEnd != null) {
                const startDate = new Date(createTimeStart);
                const endDate   = new Date(createTimeEnd);
                setDatesCreation(prevState => ({...prevState, startDate, endDate}));
            }
            if(updateTimeStart != null && updateTimeEnd != null) {
                const startDate = new Date(updateTimeStart);
                const endDate   = new Date(updateTimeEnd);
                setDatesUpdate(prevState => ({...prevState, startDate, endDate}));
            }
        }

        fetchCategories()
        .then(transformTree)
        .then(setTree)
        .then(checkCategories)
        .then(checkDates);

        fetchLocations()
        .then(setLocations);
        
    }, []);

    return (
        <>      
            <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
                <Modal.Header closeButton>
                    <Modal.Title>{t('choose.the.filter')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                    <div className="row p-2">
                        <div className={"col-12 col-md-6 p-3 border border-2 rounded-4" + (checkedCategories.length === 0 ? "" : " border-primary")}>
                            <div className="h6"> {t('config.categories')} </div>
                            <Tree   treeData={categoryTree}
                                    checkable 
                                    onCheck={onCategoryCheck}
                                    checkedKeys={checkedCategories}/> 
                        </div>
                        <div className="col-12 col-md-6 p-0 ps-md-2">
                            <div className={"col-12 p-3 mb-3 mt-3 mt-md-0 border border-2 rounded-4" + (checkedServisability == '' ? "" : " border-primary")}>
                                <div className="h6"> {t('item.condition')} </div>
                                <select className="form-select" aria-label={t('item.condition')} 
                                        name="stateOfServiceability" onChange={onConditionCheck}
                                        defaultValue={checkedServisability}>
                                <option value=""></option>
                                <option value="1">{t('condition.works')}</option>
                                <option value="2">{t('condition.repair.needed')}</option>
                                <option value="3">{t('condition.broken')}</option>
                                </select>
                            </div>
                            <div className={"col-12 p-3 mt-3 border border-2 rounded-4" + (checkedLocation == '' ? "" : " border-primary")}>
                                <div className="h6"> {t('label.location')} </div>
                                <select className="form-select" aria-label={t('item.condition')} 
                                        name="stateOfServiceability" onChange={onLocationCheck}
                                        defaultValue={checkedLocation}>
                                <option value={''}></option>
                                { locations.map((location, index) => (
                                    <option key={index} value={location.id}>{location.name}</option>
                                ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row p-2">
                            <div className={"col-12 p-3 mb-2 border border-2 rounded-4" + (creationSet == false ? "" : " border-primary")}>
                                <div className="h6"> {t('filter.created')} </div>
                                <CalendarInput 
                                    selectedDates={datesCreation}
                                    updateDates={onCreationDateChange}
                                    locale={userLocale}/>
                            </div>
                            <div className={"col-12 p-3 mt-2 border border-2 rounded-4" + (updateSet == false ? "" : " border-primary")}>
                                <div className="h6"> {t('filter.modified')} </div>
                                <CalendarInput 
                                    selectedDates={datesUpdate}
                                    updateDates={onUpdateDateChange}
                                    locale={userLocale}/>
                            </div>
                    </div>
                    </form>    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleConfirm}>
                        {t('action.search')}
                    </Button>                        
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalSelector;
