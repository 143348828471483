import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function ItemLocation ({location, locationsList, isEditing, onChange}) {

    const [locationObject, setLocation] = useState(null);
    const [nullLocation, setNull] = useState(true);
    const { t } = useTranslation();

    useEffect(()=>{
        if(locationsList != null) {
            const itemLocation = locationsList.find(loca => loca.id == location);
            setLocation(itemLocation);
            setNull(location == null);
        }
    }, [location, locationsList]);

    function handleLocationChange(event) {
        if(onChange) {
            onChange(parseInt(event.target.value));
        } 
    }

    return (
        <>
            {!isEditing ? <> 
                { !nullLocation && (
                    <div className="col-auto mini-box">
                        {(locationObject.googleMapsUrl != '' && isEditing != null) ? 
                            <a  href={locationObject.googleMapsUrl} target="_blank"
                                className="link-body-emphasis link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover">
                                <i className="bi bi-geo-alt"></i>
                                <span>{locationObject.name}</span> 
                            </a>
                            :
                            <>
                            <i className="bi bi-geo-alt"></i>
                            {locationObject.name} 
                            </>
                        }
                    </div>
                )}
            </> : <>
                <div className="col-auto mini-box">
                    <i className="bi bi-geo-alt"></i>
                    <label htmlFor="condition" className="form-label h6">{t('label.location')}</label>
                    <select className="form-select"
                        name="location" onChange={handleLocationChange}
                        defaultValue={location}>
                        <option value="" ></option>
                        {locationsList.map((loca, index) => (
                            <option key={index} value={loca.id}>{loca.name}</option>
                        ))}
                    </select>
                </div>
            </>}
        </> 
    );
}

export default ItemLocation;