import axios from 'axios';
import backendHost from './backendHost';
// Create an Axios instance
const MyItemsAxios = axios.create({
  baseURL: backendHost, // your API base URL
});

// Request interceptor to add auth token to request headers
MyItemsAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken'); // Get the token from local storage or any other storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
MyItemsAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');

    // Check if error is due to unauthorized access (401) and we have a refresh token //403-Forbidden
    if (error.response.status === 401 && refreshToken) {
      try {
        // Attempt to refresh the token
        const response = await axios.post(backendHost + '/user/refresh', refreshToken);
        const newToken = response.data;

        // Store the new token
        localStorage.setItem('accessToken', newToken);

        // Update the original request with the new token
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return MyItemsAxios(originalRequest);
      } catch (refreshError) {
        // If refresh fails, log out the user or take other actions
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // Redirect to login or show error message
        // window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default MyItemsAxios;
