import React from "react";

const ItemButtons = ({isEditing, updateItem, restoreItem, setEditingItem, setDeleteRequest}) => {

    return (
        <>
        {isEditing ? 
            <div className='col-auto p-1'>
              <span className='mx-1' onClick={updateItem}>
                  <i className="btn btn-outline-success bi bi-check-lg"></i>
              </span> 
              <span className='mx-1' onClick={restoreItem}>
                <i className="btn btn-outline-warning bi bi-x-lg"></i>
              </span>
            </div> 
            : 
            <div className='col-auto p-1'>
              <span className='mx-1' onClick={()=>{setEditingItem(true);}}>
                  <i className="btn btn-outline-info bi bi-feather"></i>
              </span> 
              <span className='mx-1' onClick={(e)=>{setDeleteRequest(true);}}>
                <i className="btn btn-outline-danger bi bi-trash"></i>
              </span>
            </div>
          }
        </>
    )
}

export default ItemButtons;
