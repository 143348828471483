import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import CategorySelector from './categoryTools/CategorySelector';
import MyItemsAxios from './backendTools/MyItemsAxios';

const requestCategoriesURl = "/category";

function CategoriesCreator({isOpen, handleClose, category, categoriesList}) {
    const [selectedCategories, setSelectedCategories] = useState([0]);
    const [currentCategory, setCurrentCategory] = useState(category);
    const { t } = useTranslation();

    useEffect( () => {

        setCurrentCategory(category);
        
        if(category.id) {
            //check category location in tree on edit case
            var selectedEdit = [];
            var bottomCategoryId = category.id;
            var loopLimit = 2;
            do {
                categoriesList.forEach(listedCategory => {
                    if(listedCategory.id == bottomCategoryId) {
                        bottomCategoryId = listedCategory.parent;
                        selectedEdit.unshift(bottomCategoryId); 
                        loopLimit = 2;
                    }
                })
                loopLimit--;
            } while (bottomCategoryId != 0 && loopLimit > 0);
            setSelectedCategories(selectedEdit);
        } 
        
    }, [category]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        currentCategory.name = currentCategory.name.trim();
        try {
          const response = await MyItemsAxios.post(requestCategoriesURl, currentCategory);
          if(response.status === 200) {
            handleClose(response.data);
          }
        } catch (err) {
          console.error('Error submitting form:', err);
        }
    };

    function handleParentChange (parentId) {
        setCurrentCategory({...currentCategory, "parent" : parentId});
    }

    function handleNameChange (e) {
        setCurrentCategory({...currentCategory, "name" : e.target.value});
    }

    function handleSelectChange (level, value) {
        setSelectedCategories((prev) => {
          const newSelectedValues = { ...prev, [level]: value };
          // Remove all lower levels
          Object.keys(newSelectedValues).forEach((key) => {
            if (Number(key) > level) {
              delete newSelectedValues[key];
            }
          });
          return newSelectedValues;
        });
        if(value == 0) {
            value = selectedCategories[level-1];
        }
        handleParentChange(value);
    };

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{category.id ? t('category.edit') : t('category.create') }</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className='col-12 my-3'>
                    <span>{t('label.name')}:</span>
                </div>
                <div className='col-12 my-3'>
                    <input  type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={currentCategory.name}
                            onChange={handleNameChange}
                            required
                    />
                </div>
                <div className='col-12 my-3'>
                    <span>{t('category.placement')}:</span>
                </div>
                <CategorySelector   categoriesList={categoriesList}
                                    selectedValues={selectedCategories}
                                    onChange={handleSelectChange} 
                                    blockStyle='col-12 my-3' 
                                    level={1}
                                    rootId={category.id ? category.id : undefined}/>
            </Modal.Body>
            <Modal.Footer>
                <button type='submit' className='btn btn-outline-primary mx-2'> {t('action.confirm')} </button>        
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default CategoriesCreator;
