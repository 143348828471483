import React from "react";
import { useTranslation } from 'react-i18next';
import ItemCard from './itemTools/ItemCard';

const RenderListItems = ({itemsPagingData, categoriesList, locationsList}) => {

    const { t } = useTranslation();

    return (
        <div className='col-12'>
        {itemsPagingData ? 
            (
                <div className='row p-2'>
                    {itemsPagingData.content.length ? itemsPagingData.content.map((item,index) => (
                    <ItemCard item={item} key={index} categoryList={categoriesList} locations={locationsList}/>
                    ) ) : (
                    <div className='h3 rounded-4 bg-light p-5'> 
                    <p>{t('item.no.result')}</p>
                    </div>
                    )}
                </div>
            ) : (
                <div className='row p-5 justify-content-center'>
                    <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t('label.loading')}</span>
                    </div>
                </div>
            )
        }
        </div>
    );
}

export default RenderListItems;
