import React, { useState } from 'react';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useTranslation } from 'react-i18next';
import './NoteStyle.css'; // Import the CSS file

const NoteForm = ({itemId, addNoteOnPage}) => {
    const [comment, setComment] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [confirmButtonStyle, setConfirmButtonStyle] = useState("btn rounded-4 btn-outline-secondary m-2");
    const { t } = useTranslation();
    const addNoteUrl = "/item/" + itemId + "/note";

    const handleCommentChange = (event) => {
        setComment(event.target.value);
        if(comment) {
            setConfirmButtonStyle("btn rounded-4 btn-outline-primary m-2");
        }
    };

    const handleInputFocus = () => {
        setIsActive(true);
    };

    const handleInputBlur = () => {
        if (!comment) {
            setIsActive(false);
            setConfirmButtonStyle("btn rounded-4 btn-outline-secondary m-2");
        }
    };

    const noteFormData = {  id : '',
                            content : '',
                            date : '',
                            itemDto : null
                        };

    function handleReset() {
        setComment('');
        setIsActive(false);
        setConfirmButtonStyle("btn rounded-4 btn-outline-secondary m-2");
    }

    async function sendNote() {
        try {
            const savedNoteResponse = await MyItemsAxios.post(addNoteUrl, noteFormData);
            //console.log('Success update');
            if(addNoteOnPage != null) {
                addNoteOnPage(savedNoteResponse.data);
            }            
        } catch (error) {
            console.log('Update error');
        }
    }

    const updatePageAfterSending = () => {
        setComment('');
        setIsActive(false);
    }

    const handleAddNote = (event) => {
        event.preventDefault();
        if(comment) {
            noteFormData.content = comment;
            sendNote().then(updatePageAfterSending);
        }
    }

    return (
        <div className="p-0">
        {isActive ? (
            <div className="col-12 mt-2 py-2 px-0">
            <form onSubmit={handleAddNote} onReset={handleReset}>
                <div className="form-group">
                <textarea
                    className="form-control comment-input mt-2 rounded-4 p-2"
                    placeholder={t('add.note')}
                    rows="1"
                    value={comment}
                    onChange={handleCommentChange}
                    onBlur={handleInputBlur}
                    autoFocus
                ></textarea>
                </div>
                <div className='d-flex justify-content-end'> 
                    <button type='reset'  className='btn btn-light rounded-4 m-2'>{t('action.cancel')}</button>
                    <button type='submit' className={confirmButtonStyle}> {t('action.confirm')}</button>
                </div>
            </form>
            </div>
        ) : (
            <div className='p-0'>
                <div className="col-12 my-3">
                    <input
                        type="text"
                        className="form-control comment-input rounded-4 p-2"
                        placeholder={t('add.note')}
                        onFocus={handleInputFocus}
                    />
                </div>
            </div>
        )}
        </div>
    );
};

export default NoteForm;
