import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ModalConfirm({message, isOpen, onConfirm, onDecline, props}) {

  const { t } = useTranslation();

  const handleClose = () =>  {
    if(onDecline != null) {
        if(props == null) {
          onDecline();
        } else {
          onDecline(props);
        }
    }
  }

  const handleConfirm = () => {
    if(onConfirm != null) {
      if(props == null) {
        onConfirm();
      } else {
        onConfirm(props);
      }
    }
  }
  
  return (
    <>      
      <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm.please')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            {t('action.cancel')}
          </Button>
          <Button variant="outline-primary" onClick={handleConfirm}>
            {t('action.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalConfirm;