import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Определяем источник перехода
  const previousPage = location.state?.from;

  const handleGoBack = () => {
    if (previousPage) {
      navigate(-1); // Переход на предыдущую страницу
    } else {
      navigate('/page/1'); // Если нет предыдущей страницы, можно задать дефолтный маршрут
    }
  };

  return (
    <button
      onClick={handleGoBack}
      className="btn btn-primary"
    >
      {t('action.back')}
    </button>
  );
};

export default BackButton;
