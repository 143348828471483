import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import MyItemsAxios from './backendTools/MyItemsAxios';
import { getLocations } from './LocationsList';

const itemAddURl = "/item";

function ItemInserter({isOpen, handleClose}) {
    const [formData, setFormData] = useState({
      name : '',
      location : '',
      description : '',
      stateOfServiceability : 0
    });
    const [error, setError] = useState(null);
    const [locations, setLocations] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    function handleLocationUpdate(event) {
      const newLocationId = parseInt(event.target.value);
      setFormData({ ...formData, location: newLocationId });
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      formData.name = formData.name.trim();
      //formData.location = formData.location.trim();
      if(formData.location === '') {
        formData.location = null;
      }
      if(formData.description) {
        formData.description = formData.description.trim();
      }
      try {
        const response = await MyItemsAxios.post(itemAddURl, formData);
        console.log('Form submitted successfully:', response.data);
        handleClose();
        navigate("/item/"+response.data.id); // Redirect to success page
      } catch (err) {
        console.error('Error submitting form:', err);
        setError(err.message);
      }
    };

    useEffect(()=>{
      if(isOpen && locations.length === 0) {
        getLocations()
        .then(setLocations);
      }
    }, [isOpen]);
  
    return (
      <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
        <Modal.Header closeButton>
            <Modal.Title>{t('item.create')}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
            <div className='col-12'>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  <span>{t('label.name')}</span>
                  <span className='text-danger'>*</span>
                </label>
                <input  type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  <span> {t('item.description')}</span>
                </label>
                <input  type="description"
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                  <label htmlFor="location" className="form-label">{t('label.location')}</label>
                  <select className="form-select"
                      name="location" onChange={handleLocationUpdate}>
                      <option value="" ></option>
                      {locations.map((loca, index) => (
                          <option key={index} value={loca.id}>{loca.name}</option>
                      ))}
                  </select>
              </div>
              <div className="mb-3">
                <label htmlFor="location" className="form-label">{t('item.condition')}</label>
                <select className="form-select" aria-label="{t('item.condition')}" 
                        name="stateOfServiceability" onChange={handleChange}
                        defaultValue="0">
                  <option value="0"></option>
                  <option value="1">{t('condition.works')}</option>
                  <option value="2">{t('condition.repair.needed')}</option>
                  <option value="3">{t('condition.broken')}</option>
                </select>
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-outline-primary position-relative">{t('item.create')}</button>       
        </Modal.Footer>
        </form>
      </Modal>
    );
  }


export default ItemInserter;