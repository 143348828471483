import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Landing.css';

function HelpPage() {

  const { t } = useTranslation();
  
  return (
    <Container className='pt-5 mt-5' style={{maxWidth:"700px"}}>
      <h2> Here will be some description soon</h2>
      <div className='d-block p-3'>
        <p> Page is under development </p>
      </div>        
    </Container>
  );
}

export default HelpPage;
