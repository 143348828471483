import React from "react";
import { useTranslation } from 'react-i18next';

function ItemDescription ({description, isEditing, onChange}) {
    const { t } = useTranslation();

    function handleDescriptionChange(event) {
        if(onChange) {
            onChange(event.target.value);
        } 
    }
    
    return (
        <>
            { ((description != null && description !== "") || isEditing) && (
                <div className="col-auto py-3 mt-0 mt-lg-3">  
                    <span className="h6">{t('item.description')}:</span><br></br>
                    {isEditing ? 
                        <textarea
                        className="form-control comment-input mt-2"
                        value={description}
                        onChange={handleDescriptionChange}>
                        </textarea> 
                        : 
                        <div> {description} </div>
                }
                </div>
            )}
        </>
    );
}

export default ItemDescription;