import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import MyItemsAxios from './backendTools/MyItemsAxios';

import { requestLocationURl } from './LocationsList';

function LocationCreator({isOpen, handleClose, location}) {
    const [currentLocation, setCurrentLocation] = useState(location);
    const { t } = useTranslation();

    useEffect( () => {
        setCurrentLocation(location);
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        currentLocation.name = currentLocation.name.trim();
        if(currentLocation.id) {
            try {
                const response = await MyItemsAxios.put(requestLocationURl + '/' + currentLocation.id, currentLocation);
                if(response.status === 200) {
                    handleClose(response.data);
                }
            } catch (err) {
                console.error('Error submitting form:', err);
            }
        } else {
            try {
                const response = await MyItemsAxios.post(requestLocationURl, currentLocation);
                if(response.status === 200) {
                    handleClose(response.data);
                }
            } catch (err) {
                console.error('Error submitting form:', err);
            }
        }
        
    };

    function handleNameChange (e) {
        setCurrentLocation({...currentLocation, "name" : e.target.value});
    }

    function handleAddressChange (e) {
        setCurrentLocation({...currentLocation, "address" : e.target.value});
    }

    function handleLinkChange (e) {
        setCurrentLocation({...currentLocation, "googleMapsUrl" : e.target.value});
    }

    return (
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{location.id ? t('locations.edit') : t('locations.create') }</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
                <div className='col-12 my-3'>
                    <span>{t('label.name')}:</span>
                </div>
                <div className='col-12 my-3'>
                    <input  type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={currentLocation.name}
                            onChange={handleNameChange}
                            required
                    />
                </div>
                <div className='col-12 my-3'>
                    <span>{t('locations.address')}:</span>
                </div>
                <div className='col-12 my-3'>
                    <input  type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={currentLocation.address}
                            onChange={handleAddressChange}
                            required
                    />
                </div>
                <div className='col-12 my-3'>
                    <span>{t('locations.link')}:</span>
                </div>
                <div className='col-12 my-3'>
                    <input  type="text"
                            className="form-control"
                            id="googleMapsUrl"
                            name="googleMapsUrl"
                            value={currentLocation.googleMapsUrl}
                            onChange={handleLinkChange}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type='submit' className='btn btn-outline-primary mx-2'> {t('action.confirm')} </button>        
            </Modal.Footer>
            </form>
        </Modal>
    );
}

export default LocationCreator;
