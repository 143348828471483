import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MyItemsAxios from './backendTools/MyItemsAxios';
import ModalConfirm from './components/ModalConfirm';
import LocationCreator from './LocationCreator';

const requestLocationURl = "/location";
const requestLocationsListURl = "/location/user";

const nullLocation = {id : null, name : '', address : '', googleMapsUrl : '' };

async function getLocations () {
    try {
        const respondedLocations = await MyItemsAxios.get(requestLocationsListURl);
        //console.log("Succes received categories", respondedCategories.data);
        return respondedLocations.data;
    } catch (error) {
        console.error('Error receiving data:', error);
    }
};

function transformLocationsTree(locations) {
    const locationsTree = [];
    locations.forEach(element => {
        const locationNode = {
            id : element.id,
            key : element.id,
            title : element.name
        };
        if(element.googleMapsUrl) {
            locationNode.googleMapsUrl = element.googleMapsUrl;
        }
        locationsTree.push(locationNode);
    });
    return locationsTree;
}

function LocationsList () {
    const [locationsTree, setLocationsTree] = useState(null); 
    const [locationsArray, setLocationsArray] = useState([]);

    const [isEditorOpen, setEditorOpen] = useState(false);
    const [editingLocation, setEditingLocation] = useState(nullLocation);

    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deletingLocation, setDeletingLocation] = useState('');
    const [deletingMessage, setDeletingMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    function onLocationClick(selectedKeys, info) {
        openEditor(parseInt(selectedKeys[0]));
    }

    function openEditor(locationId) {
        if(locationId) {
            setEditingLocation(locationsArray.find( listedLocation => listedLocation.id == locationId));
        } else {
            setEditingLocation(nullLocation);
        }
        setEditorOpen(true);
    }

    function closeEditor(location) {
        if(location) {
            const foundIndex = locationsArray.findIndex(loca => loca.id == location.id);
            if(foundIndex !== -1) {
                locationsArray[foundIndex] = location;
            } else {
                locationsArray.push(location);
            }
            
            setLocationsArray(locationsArray);
            setLocationsTree(transformLocationsTree(locationsArray));
        }
        setEditorOpen(false);
    }

    function onDeletePress(event, location) {
        event.stopPropagation();
        setDeleteModalState(true);
        setDeletingLocation(location);
    }

    function deleteLocation() {
        MyItemsAxios.delete(requestLocationURl + '/' + deletingLocation.id)
            .then(()=>{navigate(0)});
    }

    const renderTreeNodes = (data) =>
        data.map((item) => {    
          return {
            title: (
              <span>
                {item.title} 
                <span>  <i className="text-danger bi bi-trash p-1" onClick={(e)=>{onDeletePress(e, item);}}> </i>  </span> 
                {item.googleMapsUrl && <span> <a href={item.googleMapsUrl} target="_blank" onClick={(e) => {e.stopPropagation();}}> <i className='bi bi-geo-alt'> </i> </a></span>}
              </span>
            ),
            key: item.key,
          };
        });

    useEffect(() => {

        function processLocations(locations) {
            console.log("responded locations:", locations);
            setLocationsArray(locations);
            setLocationsTree(transformLocationsTree(locations));
        }

        getLocations()
        .then(processLocations);
        

    }, []);

    useEffect(() => {
        var newMessage = t('locations.delete');
        newMessage += ' "' + deletingLocation.title + '"?';
        setDeletingMessage(newMessage);
    }, [deletingLocation]);

    return (
        <>
            {locationsTree && (
                <div className="row border rounded-4 bg-light">
                    <div className='col-12 p-3'>
                        <Tree   treeData={renderTreeNodes(locationsTree)}
                                onSelect={onLocationClick}
                                showLine
                                defaultExpandAll 
                                className='bg-light'/>
                    </div>
                    <div className='col-12 m-3'>
                        <span className='btn btn-outline-success' onClick={()=>{openEditor(0);}}> <i className="bi bi-file-earmark-plus"></i></span>
                    </div>
                </div>
            )}
            <LocationCreator    isOpen={isEditorOpen}
                                handleClose={closeEditor}
                                location={editingLocation} />
            <ModalConfirm message={deletingMessage}
                          isOpen={deleteModalState}
                          onConfirm={deleteLocation}
                          onDecline={()=>{setDeleteModalState(false);}}/>          
        </>
    );
}

export default LocationsList;
export {requestLocationURl, requestLocationsListURl, transformLocationsTree, getLocations};
