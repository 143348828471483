import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Tree } from "antd";
import { useTranslation } from 'react-i18next';
import transformTree, {getCategoryIdByKey, getCategoryKeyById, isCategoryEndByKey} from '../categoryTools/CategoryTree';

const ModalCategories = ({isOpen, onClose, categoryList, categoriesOfItem, handleAddCategory}) => {

    const [categoryTree, setTree] = useState(null);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const { t } = useTranslation();

    function handleClose() {
        if(onClose != null) {
            onClose();
        }
    }

    function handleConfirm () {
        var selectedCategories = [];
        //get categoryIDs by keys
        checkedKeys.forEach(categoryKey => {
            if(isCategoryEndByKey(categoryKey)) {
                selectedCategories.push(getCategoryIdByKey(categoryKey));
            }
        });
        
        handleAddCategory(selectedCategories);
        if(onClose != null) {
            onClose();
        }
    }
      
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue);
    };
    

    useEffect(()=>{
        if(categoryList) {
            var selectedKeys = [];
            //sort categories
            //return structured array
            setTree(transformTree(categoryList));

            categoriesOfItem.forEach(categoryId => {
                selectedKeys.push(getCategoryKeyById(categoryId));
            });
            setCheckedKeys(selectedKeys);
        }
    }, [categoryList, categoriesOfItem]);

    return (     
      <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
        <Modal.Header closeButton>
            <Modal.Title>{t('select.categories')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
            <Tree   treeData={categoryTree}
                    checkable 
                    defaultExpandAll
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}/>              
            </form>    
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={handleConfirm}>
                {t('action.confirm')}
            </Button>                        
        </Modal.Footer>
    </Modal>
    );
}

export default ModalCategories;
