import React, { useEffect, useState } from "react";

const CategorySelector = ({categoriesList, onChange, blockStyle, selectedValues, level, rootId}) => {
    const [selectorCategories, setSelectorCategries] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    function handleSelectorChange(event) {
        const eventCategoryId = event.target.value;
        setSelectedCategoryId(eventCategoryId);
        if(onChange != null) {
            onChange(level, eventCategoryId);
        }
    }

    useEffect( () => {
        function getSubcategoriesList(parent) {
            return categoriesList.filter(category => category.parent == parent && category.id != rootId);
        }
        if(categoriesList != null) {
            setSelectorCategries(getSubcategoriesList(selectedValues[level-1]));
            if(selectedValues[level]) {
                setSelectedCategoryId(selectedValues[level]);
            }
        }
    }, [categoriesList, selectedValues, level, rootId]);


    if(blockStyle == null) {
        blockStyle = 'col-auto';
    }

    return(
        <div className="p-0"> 
            {selectorCategories.length > 0 &&
                (<div className={blockStyle}>
                    <select onChange={handleSelectorChange}
                            className="form-control"
                            value={selectedCategoryId} >                      
                        <option value={0}>  </option>
                        { selectorCategories.map((category, index) => (
                            <option key={index} value={category.id}>{category.name} </option>)
                        ) } 
                    </select>
                </div>)
            }
            { selectedCategoryId != 0 && (
                <CategorySelector   categoriesList={categoriesList} 
                                    onChange={onChange}
                                    blockStyle={blockStyle}
                                    selectedValues={selectedValues}
                                    level={level + 1}
                                    rootId={rootId}/>
            )}
        </div>
    );
}

export default CategorySelector;