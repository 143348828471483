import React, {useState, useEffect, useRef} from "react";

import { DateRange } from 'react-date-range';
import { enGB, he, ru, uk } from 'date-fns/locale'; // Импортируйте локализацию

import 'react-date-range/dist/styles.css'; // Основные стили
import 'react-date-range/dist/theme/default.css';

const localization = {en:enGB, ru:ru, he:he, ua:uk};

const CalendarInput = ({selectedDates, updateDates, locale}) => {

    const [selectionRange, setSelectionRange] = useState(selectedDates);

    const [showCalendar, setShowCalendar] = useState(false);
    const calendarRef = useRef(null);
    
    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(()=>{
        if(selectedDates) {
            setSelectionRange(selectedDates);
        }
    }, [selectedDates]);
    
    const handleInputClick = () => {
        setShowCalendar(!showCalendar);
    };

    const handleChange = (ranges) => {
        setSelectionRange(ranges.selection);
        if(updateDates) {
            updateDates(ranges.selection, true);
        }
    }
    
    return (
        <div>
        <input
            type="text"
            readOnly
            className="form-control"
            value={`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`}
            onClick={handleInputClick}
        />
        {showCalendar && (
            <div ref={calendarRef}>
                <DateRange
                    ranges={[selectionRange]}
                    onChange={handleChange}
                    editableDateInputs={true}
                    locale={localization[locale]}
                    dateDisplayFormat={"dd-MM-yyyy"}
                />
            </div>
        )}
        </div>
    );
}

export default CalendarInput;
