import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Landing.css';

function Landing() {

  const { t } = useTranslation();
  
  return (
    <div>
      <Container fluid className="jumbotron text-center mt-4 py-2 py-md-5 rounded-4">
        <Container>
          <h2>{t('main.welcome')}</h2>
          <div className='d-block'>
            <p> <br/> </p>
          </div>
          <div className='d-block'>
            <p> <br/> </p>
          </div>
          <div className='d-none d-lg-block'>
            <p> <br/></p>
          </div>
          <div className='d-none d-lg-block'>
            <p> <br/></p>
          </div>
          <div className='d-none d-xl-block'>
            <p> <br/></p>
          </div>
          <p>{t('main.description')}</p>
          <Button variant="primary" href="/login">{t('action.login')}</Button>         
        </Container>
      </Container>
    </div>
  );
}

export default Landing;
