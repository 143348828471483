import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ItemImage from "./ItemImage";
import ItemName from '../itemTools/ItemName';
import ItemServicability from '../itemTools/ItemServicability';
import ItemLocation from '../itemTools/ItemLocation';
import ItemDeliveryStatus from '../itemTools/ItemDeliveryStatus';
import ItemCategories from "./ItemCategories";

const ItemCard = ({item, categoryList, locations}) => {
    const navigate = useNavigate();
    const location = useLocation();

    function handleClick() {
        navigate("/item/"+item.id, { state: { from: location.pathname } });
    }

    return (
        <div className="card my-2 p-0 rounded-4" onClick={handleClick} style={{ cursor: 'pointer' }}>
            { item.categories.length !== 0 && 
                <div className="card-header p-1">
                    <ItemCategories categoriesOfItem={item.categories} categoryList={categoryList}/>
                </div>}
            <div className="row g-0 p-2">
                <div className="col-4">
                    <ItemImage photo={item.photo}/>
                </div>
                <div className="col-8">
                <div className="card-body ps-3 py-1 ps-md-5">
                    <div className="card-title">
                        <ItemName name={item.name}/>
                        <ItemServicability serviceable={item.stateOfServiceability}/>
                    </div>
                    <div className="card-text">
                        <ItemLocation location={item.location} locationsList={locations}/>          
                        <ItemDeliveryStatus status={item.delivered}/>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ItemCard;
