import React, { useEffect, useState } from "react";
import axios from "axios";
import backendHost from "./backendTools/backendHost";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const RegistrationCheck = ({onConfirm}) => {
    const query = new URLSearchParams(useLocation().search);
    const activationCode = query.get('activationCode');
    const [isConfirmed, setConfirmed] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    function checkKey() {
        try {
            axios.post(backendHost + '/user/confirmRegistration', activationCode, {
                headers: {
                  'Content-Type': 'text/plain'
                }
              })
            .then((checkResult)=>{
                if(checkResult.status === 200) {
                    setConfirmed(true);
                    onConfirm(checkResult.data);
                    navigate("/");
                }
            });
        } catch {
            //error confirm
        }
    }

    useEffect(()=>{
        //send request with key
    }, []);

    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    {isConfirmed ? (
                        <div>
                        <p>{t('label.congratulations')}</p>
                        <p>{t('if.you.see.this.message')}</p>
                        </div>
                    ):(
                        <div>
                        <p>{t('press.button')}</p>
                        <button className="btn btn-outline-success" onClick={checkKey}>{t('confirm.account')}</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default RegistrationCheck;
