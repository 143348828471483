import React from "react";
import CategoriesList from "./CategoriesList";
import LocationsList from "./LocationsList";
import { useTranslation } from 'react-i18next';

const Configuration = () => {
    const { t } = useTranslation();

    return(
        <>
    <div className="container mt-5 p-4 pt-5" style={{'maxWidth': '700px'}}>
        <div className="accordion" id="categoriesAccord" >
            <div className="accordion-item rounded-4 body-back-color">
                <h2 className="accordion-header  rounded-4">
                <button className="accordion-button collapsed  rounded-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    {t('config.categories')}
                </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse body-back-color" data-bs-parent="#categoriesAccord">
                <div className="accordion-body">
                    <CategoriesList />
                </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container p-4 pt-2" style={{'maxWidth': '700px'}}>
        <div className="accordion rounded-4" id="locationsAccord" >
            <div className="accordion-item rounded-4 body-back-color">
                <h2 className="accordion-header rounded-4">
                <button className="accordion-button collapsed rounded-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    {t('config.locations')}
                </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse body-back-color" data-bs-parent="#locationsAccord">
                <div className="accordion-body">
                    <LocationsList />
                </div>
                </div>
            </div>
        </div>
    </div>

    </>
    );
}

export default Configuration;