import React from "react";
import { useTranslation } from 'react-i18next';

const CheckMail = ({username, email}) => {
    const { t } = useTranslation();
    
    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    <div className="">
                        <p className="blockquote">{username && <>{username}, {t('registration.done')}</>}</p>
                        <p>{t('check.mail')} {email && <>({email})</>} {t('for.validation.message')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckMail;