import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { useCookies } from 'react-cookie';

import NavBar from './NavBar';
//import Footer from './Footer';


import ItemReceiver from './ItemReceiver';
import CategoriesList from './CategoriesList';
import LoginPage from './LoginPage';
import RegistrationEntry from './RegistrationEntry';
import CheckMail from './CheckMail';
import Landing from './Landing';
import RegistrationCheck from './RegistrationCheck';
import UserList from './adminPages/UserList';
import RestorePassword from './RestorePassword';
import Configuration from './Configuration';
import MainPage from './MainPage';
import ListItems from './ListItems';
import HelpPage from './HelpPage';

function App() {

    const [isUserLogged, setUserLogged] = useState(false);
    const [cookies, setCookie] = useCookies(['user', 'email']);

    useEffect( () => {
        if(localStorage.getItem('refreshToken')) {
            setUserLogged(true);
        }
    }, []);

    function onLogin(tokens) {
        setUserLogged(true);
        localStorage.setItem('refreshToken', tokens.refreshToken);
        localStorage.setItem('accessToken' , tokens.accessToken);        
    }

    function onLogout() {
        setUserLogged(false);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
    }

    function onRegister(user, email) {
        const expires = new Date();
        expires.setMonth(expires.getMonth() + 1); // Срок действия куки - 1 месяц
        setCookie('user',  user,  { path: '/', expires });
        setCookie('email', email, { path: '/', expires });
    }

    return (
        <Router>
            {<NavBar    isLogged={isUserLogged} onLogout={onLogout} />}
            <Routes>
                <Route path="/"                     element={isUserLogged ? <MainPage /> : <Landing />}/>
                <Route path="/login"                element={<LoginPage onLogin={onLogin}/>} />
                <Route path='/registration'         element={<RegistrationEntry onRegister={onRegister} />} />
                <Route path='/restore-password'     element={<RestorePassword onLogin={onLogin} /> } />
                <Route path="/page/:pageNumber"     element={<ListItems      />} />
                <Route path="/item/:id"             element={<ItemReceiver      />} />
                <Route path="/categories"           element={<CategoriesList    />} />
                <Route path="/configuration"        element={<Configuration     />} />
                <Route path="/checkMail"            element={<CheckMail username={cookies.user} email={cookies.email}/>} />
                <Route path="/confirmRegistration"  element={<RegistrationCheck onConfirm={onLogin}/>} />
                <Route path="/help"                 element={<HelpPage          />} />
                <Route path="/admin"                element={<UserList/>} />
            </Routes>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;
