import React, { useState } from "react";
import ModalSelector from "./components/ModalSelector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageInNavBarToggler from "./components/LanguageInNavBarToggler";

const NavBar = ({isLogged, onLogout}) => {
    const [isFilterSelectorActive, setFilterSelectorActive] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    

    function handleLogout() {
        if(onLogout != null) {
            onLogout();
        }
        navigate("/");
    }

    function openCategorySelector() {
        setFilterSelectorActive(true);
    }

    function closeCategorySelector() {
        setFilterSelectorActive(false);
    }

    return (
        <nav className={"navbar bg-dark border-bottom border-body navbar-expand fixed-top" + (!isLogged ? "-md" : "")}  data-bs-theme="dark">
            <div className="container" style={{'maxWidth': '700px'}}>
                <a className="navbar-brand" href="/">My items</a> 
                <LanguageInNavBarToggler/>
                {isLogged ? 
                    <div className="navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <div className="nav-link" style={{ cursor: 'pointer' }} onClick={openCategorySelector}>
                                    <i className="bi bi-search h5"></i>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/configuration">
                                    <i className="bi bi-gear h5"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link" style={{ cursor: 'pointer' }} onClick={handleLogout}> 
                                    <i className="bi bi-power h5" title={t('action.logout')}></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    :
                    <>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/login" aria-disabled="false">{t('action.login')}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/registration">{t('action.registration')}</a>
                            </li>
                        </ul>
                        
                    </div>
                    </>
                    }
                {isLogged && 
                    <>
                    <ModalSelector  isOpen={isFilterSelectorActive} onClose={closeCategorySelector}/> 
                    </>
                }
            </div>
        </nav>
    );
};

export default NavBar;