function getCategoryById(categoryList, categoryId) {
    const index = categoryList.findIndex(category => category.id == categoryId);
    return categoryList[index];
}

function getCategoryBranch(categoryList, categoryId) {
    var parentCategory = getCategoryById(categoryList,categoryId);
    var categoryBranch = parentCategory;

    while(parentCategory.parent != 0 && parentCategory.parent != null) {
        parentCategory = getCategoryById(categoryList,parentCategory.parent);
        categoryBranch = {child: categoryBranch, ...parentCategory};
    }

    return categoryBranch;
}

export default getCategoryBranch;
export {getCategoryById};