import React from "react";

function ItemName ({name, isEditing, updateName}) {

    function onNameChange(event) {
        if(updateName) {
            updateName(event.target.value);
        }
    }
    return (
        <>
        {isEditing ? 
            <input  type="text" 
                    id="name"
                    className="form-control"
                    value={name}
                    onChange={onNameChange}/> 
                    
            :
            <span className="h3 text-wrap"> {name}</span>
        }
        </>
    );
}

export default ItemName;