import React from "react";

const PageScroll = ({pageList, currentPage, query}) => {

    const buttonStyle = 'btn btn-light';
    const activeStyle = 'btn btn-secondary';
    return (
        <div className='col-12 btn-group my-2'>
        { pageList != null && pageList.map((pageNumber,index) => (
          (query ? (
            <a  key={index} href={"/page/" + pageNumber+'?'+query} 
                className={pageNumber == currentPage ? activeStyle : buttonStyle}> 
                    <i className="bi bi-book-half"></i> {pageNumber} 
            </a> 
          ) : (
            <a  key={index} href={"/page/" + pageNumber} 
                className={pageNumber == currentPage ? activeStyle : buttonStyle}>
                    <i className="bi bi-book-half"></i> {pageNumber} 
            </a> 
          ))
        )) }
      </div>
    );
}

export default PageScroll;
