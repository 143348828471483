import React, { useEffect, useState } from "react";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import UserButtons from "./UserButtons";


const UserList = () => {

    const [usersList, setUserList] = useState([]);

    useEffect(() => {
        async function getUsers() {
            try {
                const response = await MyItemsAxios.get('/admin/get-all-users');
                setUserList(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }
        
        getUsers();

    }, []);

    function deactivateUser(userId) {
        MyItemsAxios.put('/admin/' + userId + '/deactivate');
    }

    function activateUser(userId) {
        MyItemsAxios.put('/admin/' + userId + '/activate');
    }

    function handleBlockButton(userId) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if(userIndex != -1) {
            if(usersList[userIndex].activated) {
                deactivateUser(userId);
            } else {
                activateUser(userId);
            }
            window.location.reload();
        }
    }


return (
    <div className="container mt-5 p-3 bg-light" style={{'maxWidth':'900px'}}>
            <table className="table table-stripped">
                <thead>
                    <tr>
                        <td> ID </td>
                        <td> Username </td>
                        <td> E-mail </td>
                        <td> Roles </td>
                        <td> Status </td>
                        <td> Actions </td>
                    </tr>
                </thead>
                <tbody>
                {usersList && usersList.map((user, index) => (
                    <tr key={index}>
                        <td> {user.id} </td>
                        <td> {user.username} </td>
                        <td> {user.email} </td>
                        <td> {user.roles} </td>
                        <td> {user.activated ? "Activated" : "Registered"} </td>
                        <td> <UserButtons   userId={user.id} 
                                            userActivated={user.activated} 
                                            userActivationControl={handleBlockButton}/> 
                        </td>
                    </tr>
                )) }
                </tbody>
            </table>
    </div>
);
}

export default UserList;