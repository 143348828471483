import React, { useEffect, useState } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enGB, he, uk, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const locales = { en: enGB, he, ru, ua: uk};

const TimeLeft = ({ targetDate, localeCode }) => {
    const [timeLeft, setTimeLeft] = useState('');
    const { t } = useTranslation();
    const locale = locales[localeCode] || enGB;
  
    useEffect(() => {
      const updateRemainingTime = () => {
        const target = parseISO(targetDate);
        var difference = formatDistanceToNow(target, { locale, addSuffix: true });
        if (difference.includes('in less')) {
          difference = t('less.than.minute');
        }
        setTimeLeft(difference);
      };
  
      updateRemainingTime();
      //update time every 20 secs
      const intervalId = setInterval(updateRemainingTime, 20000);
  
      return () => clearInterval(intervalId);
    }, [targetDate, localeCode]);
  
    return <div>{timeLeft}</div>;
  };

  export default TimeLeft;